<template>
  <v-container>
    <v-row>
      <v-breadcrumbs large :items="items_nav">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-card class="ma-4">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
        <v-btn
          class="mx-2"
          @click="saveOrdem()">
          SALVA ORDEM
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="dataTable"
        :search="search"
        item-key="id"
        class="elevation-1"
        hide-default-footer
        disable-pagination
      >

        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            @change="changeOrdem(props.items)"
          >
            <tr v-for="(item, index) in props.items" :key="index">
              <td><v-icon large style="cursor:pointer;">mdi-drag</v-icon></td>
              <td>{{ item.nome_empresa }}</td>
              <td>{{ item.tag || "Indefinido" }}</td>
              <td>{{ getCategoria(item.categoria_id) }}</td>
              <td>{{ item.uf }}</td>
              <td><v-row>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2 blue darken-3"
                @click="viewUsuario(item)"
              >
                <v-icon small dark>mdi-account-search</v-icon>
              </v-btn>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2  yellow darken-3"   
                :to="{name: 'dashboard-fornecedor', params: {id: item.id}}"
              >
                <v-icon small dark>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2  red darken-3"
                @click="changeStatus(item, 'reprovar')"
              >
                <v-icon small dark>mdi-close-thick</v-icon>
              </v-btn>

          </v-row></td>
            </tr>
          </draggable>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="getDataFromApi">Reiniciar</v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Realizando operação por favor aguarde...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

    <v-dialog v-model="dialogChangeStatus" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Alterar Situação
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="col">
              <b>Fornecedor:</b><br />
              {{ itemSelected.nome_empresa }}
            </div>
            <div class="col">
              <b>Data de Cadastro:</b><br />
              {{ itemSelected.data_criacao | dateTime }}
            </div>
          </v-row>
          <br>
          <p>
            Deseja realmente {{ this.itemConfirmStatus }} esse fornecedor?
          </p>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-row
            align="center"
            justify="end"
            class="my-4"
          >
            <v-btn
            class="mx-4"
              color="red darken-3 btn-modal"
              @click="dialogChangeStatus = false"
            >
              Cancelar
            </v-btn>
            <v-btn class="mx-4" color="green darken-1 btn-modal" @click="setModalStatus()">
              Continuar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMessage" width="480">
        <v-card class="mx-auto"  dark>
        <v-card-title>
            <span class="title font-weight-light">{{ message.title }}</span>
        </v-card-title>
        <v-card-text class="headline font-weight-bold">
            {{ message.body }}
        </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogViewRepresentante" max-width="600px">
      <v-card>
        <v-card-title class="headline">Representante</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <strong>Nome: </strong> {{representante.nome}}
            </v-col>

            <v-col cols="12" md="6">
              <strong>Email: </strong> {{representante.email}}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <strong>Telefone: </strong> {{itemSelected.telefone}}
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="12">
              <strong>Onde conheceu: </strong> {{representante.onde_conheceu}}
            </v-col>
          </v-row>

           <v-row>
            <v-col cols="12">
              Aceitou a política de privacidade em <strong>{{representante.politica_aceita_em | dateTime}}</strong>
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import database from "@/services/database";
import store from '@/store'
import draggable from "vuedraggable";

export default {
  name: "Fornecedores",
  components: {
    draggable,
  },
  data() {
    return {
      items_nav: [
        {
          text: "Sistema",
          disabled: false,
        },
        {
          text: "Fornecedores",
          disabled: true,
        },
        {
          text: "Listagem",
          disabled: true,
        }
      ],
      loading: true,
      search: "",
      singleSelect: false,
      selected: [],
      itemSelected: {
        nome_empresa: "",
        data_criacao: ""
      },
      message: {
        title: "",
        body: ""
      },
      dialogMessage: false,
      representante: {
        nome: "",
        email: "",
        onde_conheceu: "",
        politica_aceita_em: ""
      },
      dialogViewRepresentante: false,
      dialog: false,
      dialogProgress: false,
      dialogEdit: false,
      dialogAddress: false,
      dialogChangeStatus: false,
      itemConfirmStatus: "",
      address: [],
      registers: [],
      headers: [
        { text: "", value: "drag" },
        { text: "Nome", value: "nome_empresa" },
        { text: "Tag", value: "tag" },
        { text: "Categoria", value: "categoria_id" },
        // { text: "Telefone", value: "telefone" },
        { text: "UF", value: "uf" },
        { text: "Ações", value: "actions" },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi: async function() {
      this.loading = true;
      var data = await database.getAllFornecedores();
      this.dataTable = data;
      this.loading = false;
    },
    saveOrdem: async function() {
      this.loading = true;
      var order = [];
      await this.dataTable.forEach((item, index) => {
        order.push(item.id);
      });
      var result = await database.updateFornecedoresOrdem(order);
      this.loading = false;
      if (result) {
        this.message.title = "Operação realizada";
        this.message.body = "Posições salvas com sucesso!";
        this.dialogMessage = true;
      } else {
        this.message.title = "Operação falhou";
        this.message.body = "Verifique sua conexão e tente novamente!";
        this.dialogMessage = true;
      }
    },
    changeOrdem: async function(data) {
      this.loading = true;
      this.dataTable = data;
      this.loading = false;
    },
    getCategoria: function(id) {
        return store.getters.getCategoriaById(id).nome;
    },
    changeStatus: function(item, acao) {
      this.itemSelected = item;
      this.itemConfirmStatus = acao;
      this.dialogChangeStatus = true;
    },
    setModalStatus: async function() {
      this.dialogChangeStatus = false;
      this.dialogProgress = true;
      let novoStatus = this.itemConfirmStatus === "aprovar" ? "APROVADO" : "REPROVADO";
      let result = await database.updateFornecedorStatus(
        this.itemSelected.id,
        novoStatus
      );
      if (result) {
        this.dialogProgress = false;
        this.getDataFromApi();
      } else {
        this.dialogProgress = false;
        this.showSnackbarError = true;
      }
    },
    viewUsuario: async function(fornecedor) {
      this.dialogProgress = true;
      this.itemSelected = fornecedor;
      this.representante = await database.getUsuarioById(fornecedor.usuario_id);
      this.dialogProgress = false;
      this.dialogViewRepresentante = true;
    }
  },
};
</script>
